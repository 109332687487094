import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Typography, Divider, Button, Drawer} from '@material-ui/core'
import {Layers, Users, Calendar, Bell, Settings, LogOut, Award, User, Bookmark} from 'react-feather'
import {HeadsetMicOutlined} from '@material-ui/icons'

import {connect} from 'react-redux'
import {logout} from 'avoapp-react-common/dist/redux/auth'
import {clearEntityIDS} from 'avoapp-react-common/dist/redux/localConfigs'

import EntitySwitch from './EntitySwitch'

import '../assets/scss/Sidebar.scss'

// const MDLogoLight = require('../assets/images/monitor_dosare_logo_light.svg')
import MDLogoLight from '../assets/images/monitor_dosare_logo_light.svg'

const navigationItems = [
    {
        title: 'Abonări',
        to: '/subscriptions',
        icon: <Bookmark className="link-icon" />,
        disabled: false
    },
    {
        title: 'Proiecte',
        to: '/projects',
        icon: <Layers className="link-icon" />,
        disabled: false
    },
    {
        title: 'Clienți',
        to: '/clients',
        icon: <Users className="link-icon" />
    },
    {
        title: 'Calendar',
        to:'/calendar',
        icon: <Calendar className="link-icon" />
    },
    {
        title: 'Notificări',
        to: '/notifications',
        icon: <Bell className="link-icon" />
    },
    {
        title: 'Ambasadori',
        to: '/ambassadors',
        icon: <Award className="link-icon" />
    },
    {
        title: 'Setări',
        to: '/settings',
        icon: <Settings className="link-icon" />,
        disabled: false
    }
]

export class Sidebar extends Component {
    constructor() {
        super()

        this.state = {
            disabled: false
        }
    }

    componentDidMount() {
        this.setDisabledState()
    }

    componentDidUpdate = (prevProps) => {
        const {activeSubscriptions} = this.props

        if (activeSubscriptions && prevProps.activeSubscriptions !== activeSubscriptions) {
            this.setDisabledState()
        }
    };

    shouldDisable = () => {
        const {paidSubscriptions, activeSubscriptions} = this.props

        return activeSubscriptions > paidSubscriptions
    }

    setDisabledState = () => this.setState({disabled: this.shouldDisable()})

    handleLogout = () => {
        this.props.dispatch(logout())
        this.props.dispatch(clearEntityIDS())
    }

    render() {
        const {user} = this.props
        const{disabled} = this.state

        return (
            <Drawer variant="permanent">
                <div className="sidebar-main-div">
                    <Link to="/subscriptions" className="logo-div">
                        <img src={MDLogoLight} alt="Monitor Dosare" />
                    </Link>
                    <Divider className="divider" />
                    <div className="main-div">
                        <div className="top">
                            {navigationItems.map((item) => 
                                <MenuItem 
                                    to={item.to}
                                    icon={item.icon}
                                    disabled={item.disabled !== undefined ? item.disabled : disabled}
                                    title={item.title}
                                    key={item.title}
                                />
                            )}
                        </div>
                        <div className="bottom">
                            <Link className="link" to="/support">
                                <div className="support-link-div">
                                    <Typography className="support-link-text">Suport</Typography>
                                    <HeadsetMicOutlined className="support-link-icon" />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="profile-div">
                        <div className="user-data">
                            <div className='icon-div'>
                                <User size={30} />
                            </div>
                            <div className="user-info-div">
                                <Typography className="user-info-text">{user.first_name} {user.last_name}</Typography>
                                <EntitySwitch />
                            </div>
                        </div>
                        <Button size="small" className='logout-button' onClick={this.handleLogout}>
                            <Typography className="logout-button-text">Log Out</Typography>
                            <LogOut size={15} className="logout-button-icon" />
                        </Button>
                    </div>
                </div>
            </Drawer>
        )
    }
}

class MenuItem extends Component {
    render() {
        const {to, icon, title, disabled} = this.props

        return (
            <Link className={`link ${disabled ? 'link-disabled' : null}`} to={to} >
                <div className="link-div">
                    {icon}
                    <Typography className="link-text">{title}</Typography>
                </div>
            </Link>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.users.user,
    paidSubscriptions: state.entityReports.data.paid_subscriptions,
    activeSubscriptions: state.entityReports.data.active_subscriptions
})

export default connect(mapStateToProps)(Sidebar)

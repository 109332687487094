import React, {useMemo} from 'react'
import {Button, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'

import {isUndefined, isNull} from 'lodash'
import {isBefore, lightFormat} from 'date-fns'

import {connect} from 'react-redux'

import '../assets/scss/LimitPassed.scss'

export const LimitPassed = ({paidSubscriptions, activeSubscriptions, currentEntity}) => {
    const hasPayment = useMemo(() => {
        return !isUndefined(currentEntity.plans) && !isNull(currentEntity.plans.current.current_payment)
    }, [currentEntity.plans])

    const subscriptionEndDate = useMemo(() => {
        return hasPayment && new Date(currentEntity.plans.current.current_payment.interval.upper)
    }, [currentEntity.plans, hasPayment])

    const isSubscriptionExpired = useMemo(() => {
        return subscriptionEndDate && isBefore(subscriptionEndDate, new Date())
    }, [subscriptionEndDate])

    if(activeSubscriptions > paidSubscriptions) {
        return (
            <div id='limit-passed-container'>
                <div className="limit-passed">
                    <div className="top">
                        <div className="img-container">
                            <img 
                                alt="" 
                                className="limit-img"
                                src={require('../assets/images/subscription-limit.svg').default}
                            />
                        </div>
                        <Typography variant='h2' className='title'>
                            Ai întrecut limita de surse de date
                        </Typography>

                        {isSubscriptionExpired ? (
                            <Typography>
                                Abonamentul tău a expirat în data de{' '}
                                <b>{lightFormat(subscriptionEndDate, 'dd/MM/yyyy' )}</b>.{' '}
                                Te rugăm să achiți factura proformă primită pe mail pentru prelungirea accesului.
                            </Typography>
                        ) : (
                            <Typography>
                                Abonamentul tău curent permite {paidSubscriptions} surse de date, iar această
                                limită a fost deja atinsă. Pentru a adăuga monitorizări noi de date, îți punem 
                                la dispoziție următoarele variante:
                            </Typography>
                        )}
                    </div>
                    <div className="buttons">
                        {!isSubscriptionExpired && (
                            <Link 
                                to={{
                                    pathname: '/settings',
                                    state: {scrollToMySubscription: true}
                                }}
                                className='button upgrade-subscription'
                            > 
                                <Button size="large" variant="contained" fullWidth>
                                    <Typography className='button-text'>
                                        Mărește limita abonamentului
                                    </Typography>
                                </Button>
                            </Link>
                        )}
                        <Link to='/delete-subscriptions' className='button'>
                            <Button size="large" className='outlined' variant="outlined" fullWidth>
                                <Typography className='button-text'>
                                    Șterge din abonările existente
                                </Typography>
                            </Button>
                        </Link>
                        <Link to='/support' className='button'> 
                            <Button size="large" className='clear' variant='text' fullWidth>
                                <Typography className='button-text'>
                                    Cere asistență 
                                </Typography>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    return <React.Fragment />
}

const mapStateToProps = (state) => ({
    currentEntity: state.entities.currentEntity,
    paidSubscriptions: state.entityReports.data.paid_subscriptions,
    activeSubscriptions: state.entityReports.data.active_subscriptions
})

export default connect(mapStateToProps)(LimitPassed)

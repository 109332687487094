import React, {Component} from 'react'
import {connect} from 'react-redux'

import '../assets/scss/SubscriptionsStatus.scss'

export class SubscriptionsStatus extends Component {
    render() {
        const {activeSubscriptions, paidSubscriptions} = this.props

        return (
            <div className='subscriptions-status'>
                {activeSubscriptions && paidSubscriptions ? (
                    <p>
                        Status abonări:
                        <span className='status-number'>{activeSubscriptions}</span> 
                        <span className='status-italic'>active</span> 
                        / 
                        <span className='status-number'>{paidSubscriptions}</span>
                    </p>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    paidSubscriptions: state.entityReports.data.paid_subscriptions,
    activeSubscriptions: state.entityReports.data.active_subscriptions
})

export default connect(mapStateToProps)(SubscriptionsStatus)
